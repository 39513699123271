<template>
    <v-dialog v-model="show" width="600">
        <v-card>
            <v-card-title>Выберите тип реестра</v-card-title>
            <v-divider />
            <v-card-subtitle class="mt-4 mb-4">
                <v-radio-group v-model="target">
                    <v-radio color="primary" label="Реестр межмуниципальных маршрутов" value="mm" />
                    <v-radio color="primary" label="Реестр межрегиональных маршрутов" value="mr" />
                </v-radio-group>
                <v-progress-linear v-model="value" :active="show" :indeterminate="query" :query="true" v-show="!!loading"/>
                <span v-show="!!loading">{{ error || state }}</span>
            </v-card-subtitle>
            <v-card-text>
                <div v-if="result">
                    Реестр сформирован
                    <v-btn :href="`/api/busreports/reestr?target=` + target + `&mode=4`" color="primary" dark>Просмотр</v-btn>
                </div>
                <div v-if="!result || !!loading">
                    Выберите тип реестра и нажмите "Сформировать"
                </div>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn @click="generate" :loading="loading" color="primary" dark>Сформировать</v-btn>
                <v-btn @click="show = false" color="gray" outlined>Закрыть</v-btn>
            </v-card-actions>  
        </v-card>
    </v-dialog>
</template>

<script>
const xl = require('excel4node');

const border = {
    left: {
        style: 'thin',
        color: '#000000'
    },
    right: {
        style: 'thin',
        color: '#000000'
    },
    top: {
        style: 'thin',
        color: '#000000'
    },
    bottom: {
        style: 'thin',
        color: '#000000'
    }
};
let book, sheet, captionMM, captionMR, titleMM, titleMR, subTitleMM, subTitleMR, dataMR;

const titlesMM = [
    {name: 'Рег. № мар.', r: 3, c: 1, rows: 1, cols: 0, w: 5.71},
    {name: 'Номер марш.', r: 3, c: 2, rows: 1, cols: 0, w: 12.71},
    {name: 'Наименование маршрута', r: 3, c: 3, rows: 1, cols: 0, w: 52.57},
    {name: 'Наименование маршрута регулярных перевозок в виде наименований начального остановочного пункта и конечного остановочного пункта, наименования промежуточных остановочных пунктов', r: 3, c: 4, rows: 1, cols: 0, w: 72.29},
    {name: 'Улицы, автомобильные дороги, по которым проходит маршрут', r: 3, c: 5, rows: 1, cols: 0, w: 97.86},
    {name: 'Протяженность маршрута общая , км', r: 3, c: 6, rows: 1, cols: 0, w: 26.57},
    {name: 'Порядок посадки/высадки пассажиров', r: 3, c: 7, rows: 1, cols: 0, w: 28.29},
    {name: 'Вид регулярных перевозок', r: 3, c: 8, rows: 1, cols: 0, w: 26.57},
    {name: 'Характеристики транспортных средств', r: 3, c: 9, rows: 0, cols: 4, w: 26.57},
    {name: 'Вид ТС', r: 4, c: 9, rows: 0, cols: 0, w: 26.57},
    {name: 'Классы ТС', r: 4, c: 10, rows: 0, cols: 0, w: 26.57},
    {name: 'Экологические характеристки ТС', r: 4, c: 11, rows: 0, cols: 0, w: 26.57},
    {name: 'Максимальный срок эксплуатации ТС', r: 4, c: 12, rows: 0, cols: 0, w: 26.57},
    {name: 'Характеристики ТС, влияющие на качестао регулярных перевозок', r: 4, c: 13, rows: 0, cols: 0, w: 34.71},
    {name: 'Максимальное количество ТС каждого класса', r: 3, c: 14, rows: 1, cols: 0, w: 19.14},
    {name: 'Перевозчик (наименование, место нахождения юридического лица, ФИО индивидуального предпринимателя, в том числе участников договора простого товарищества)', r: 3, c: 15, rows: 1, cols: 0, w: 51.29},
    {name: 'Дата начала осуществления регулярных перевозок', r: 3, c: 16, rows: 1, cols: 0, w: 36.57},
    {name: 'Требования, предусмотренные законом Краснодарского края', r: 3, c: 17, rows: 1, cols: 0, w: 25}
];

const titlesMR = [
    {name: '№ п/п',       r: 3, c: 1, rows: 2, cols: 0, w: 4.57},
    {name: 'Регистрационный номер маршрута в реестре', r: 3, c: 2, rows: 2, cols: 0, w: 11},
    {name: 'Порядковый номер маршрута', r: 3, c: 3, rows: 2, cols: 0, w: 10.57},
    {name: 'Наименование маршрута', r: 3, c: 4, rows: 2, cols: 0, w: 27.43},
    {name: 'Наименование маршрута регулярных перевозок в виде наименований начального остановочного пункта и конечного остановочного пункта, наименования промежуточных остановочных пунктов', r: 3, c: 5, rows: 2, cols: 0, w: 37.14},
    {name: 'Наименование улиц и автомобильных дорог в прямом направлении', r: 3, c: 6, rows: 2, cols: 0, w: 62.71},
    {name: 'Наименование улиц и автомобильных дорог в обратном направлении', r: 3, c: 7, rows: 2, cols: 0, w: 62.71},
    {name: 'Протяженность маршрута в прямом направлении, км', r: 3, c: 8, rows: 2, cols: 0, w: 11.71},
    {name: 'Протяженность маршрута в обратном направлении, км', r: 3, c: 9, rows: 2, cols: 0, w: 14.57},
    {name: 'Порядок посадки и высадки пассажиров', r: 3, c: 10, rows: 2, cols: 0, w: 16},
    {name: 'Вид регулярных перевозок', r: 3, c: 11, rows: 2, cols: 0, w: 12.29},
    {name: 'Характеристики транспортных средств', r: 3, c: 12, rows: 0, cols: 8, w: 9},
    {name: 'Вид ТС', r: 4, c: 12, rows: 1, cols: 0, w: 9},
    {name: 'Классы ТС', r: 4, c: 13, rows: 0, cols: 4, w: 3.71},
    {name: 'ОМ', r: 5, c: 13, rows: 0, cols: 0, w: 3.71},
    {name: 'М', r: 5, c: 14, rows: 0, cols: 0, w: 3.71},
    {name: 'С', r: 5, c: 15, rows: 0, cols: 0, w: 3.71},
    {name: 'Б', r: 5, c: 16, rows: 0, cols: 0, w: 3.71},
    {name: 'ОБ', r: 5, c: 17, rows: 0, cols: 0, w: 3.71},
    {name: 'Экологические характеристки ТС', r: 4, c: 18, rows: 1, cols: 0, w: 13.14},
    {name: 'Максимальный срок эксплуатации ТС', r: 4, c: 19, rows: 1, cols: 0, w: 14.43},
    {name: 'Характеристики ТС, влияющие на качестао регулярных перевозок', r: 4, c: 20, rows: 1, cols: 0, w: 29.86},
    {name: 'Дата начала осуществления перевозок', r: 3, c: 21, rows: 2, cols: 0, w: 11.29},
    {name: 'Перевозчик (наименование, место нахождения юридического лица, ФИО индивидуального предпринимателя, в том числе участников договора простого товарищества)', r: 3, c: 22, rows: 2, cols: 0, w: 25.71}
];

export default {
    name: 'Reestr',
    data: () => ({
        show: true,
        target: null,
        loading: false,
        result: false,
        value: 0,
        query: true,
        show: true,
        error: null,
        state: null
    }),
    methods: {
        async generate() {
            if ( !this.target ) {
                return;
            }
            this.loading = true;
            this.query = true
            this.show = true
            this.value = 0
            try {
                const {id: userId, tenant: tenantId} = this.$store.state.profile;
                const code = (this.target.toUpperCase() == 'MM') ? '1.15' : '1.16';

                this.state = 'Получение данных реестра';
                let opts = {
                    type: 'query', 
                    query: `feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.Reestr_${this.target.toUpperCase()}`,
                    params: {
                    }
                };
                const data = await jet.http.post(opts);
                if ( data.error ){
                    throw data.error;
                }
                this.value += 25;

                this.state = 'Получение шаблона реестра';
                this.createTemplate(this.target.toUpperCase());
                this.value += 25;
                setTimeout(() => {
  console.log("Delayed for 1 second.");
}, "1000");
                this.state = 'Формирование реестра';
                this.generateReestr(this.target.toUpperCase(), data.result.data);
                this.value += 25;
                setTimeout(() => {
  console.log("Delayed for 1 second.");
}, "1000");
                var mimeType = 'application/vnd.ms-excel';
                book.writeToBuffer().then(function(buffer) {
                    var blob = new Blob([buffer], {type: mimeType});
                    var url = URL.createObjectURL(blob);
                    var ref = document.createElement('a');
                    ref.href = url;
                    document.body.appendChild(ref);
                    ref.click();
                    document.body.removeChild(ref);
                    window.URL.revokeObjectURL(url);
                });

            } catch(e) {
                console.log(e);
                this.error = e;
            } finally {
                this.loading = false;
            }
        },
        createTemplate( target ) {
            book = new xl.Workbook();
            sheet = book.addWorksheet("Реестр");
            captionMM = book.createStyle({
                font: {
                    color: '#000000',
                    size: 28
                },
                alignment: {
                    horizontal: 'center',
                    vertical  : 'center',
                    wrapText  : true
                }
            });
            captionMR = book.createStyle({
                font: {
                    color: '#000000',
                    size: 16
                },
                alignment: {
                    horizontal: 'center',
                    vertical  : 'center',
                    wrapText  : true
                }
            });
            titleMM = book.createStyle({
                font: {
                    color: '#000000',
                    size: 14,
                },
                alignment: {
                    horizontal: 'center',
                    vertical  : 'center',
                    wrapText  : true
                },
                border: border
            });
            titleMR = book.createStyle({
                font: {
                    color: '#000000',
                    size: 10,
                    bold: true
                },
                alignment: {
                    horizontal: 'center',
                    vertical  : 'center',
                    wrapText  : true
                },
                border: border
            });
            subTitleMM = book.createStyle({
                font: {
                    color: '#000000',
                    size: 18,
                    bold: true
                },
                alignment: {
                    horizontal: 'center',
                    vertical  : 'center',
                    wrapText  : true
                },
                border: border
            });
            subTitleMR = book.createStyle({
                font: {
                    color: '#000000',
                    size: 14,
                    bold: true
                },
                alignment: {
                    horizontal: 'center',
                    vertical  : 'center',
                    wrapText  : true
                },
                border: border
            });
            dataMR = book.createStyle({
                font: {
                    color: '#000000',
                    size: 10
                },
                alignment: {
                    horizontal: 'center',
                    vertical  : 'center',
                    wrapText  : true
                },
                border: border
            });
            if ( target == 'MM' ) {
                this.createTemplateMM();
            } else {
                this.createTemplateMR();
            }
        },
        createTemplateMM() {
            const cols = 17;
            const name = 'Реестр межмуниципальных маршрутов регулярных перевозок Краснодарского края';
            sheet.cell(1, 1, 1, cols, true)
                .string(name)
                .style(captionMM);
            titlesMM.map( (title) => {
                sheet.cell(title.r, title.c, title.r + title.rows, title.c + title.cols, true)
                    .string(title.name)
                    .style(titleMM);
                if ( !!title.w ) {
                    sheet.column(title.c).setWidth(title.w);
                }
            });
        },
        createTemplateMR() {
            const cols = 22;
            const name = 'Реестр смежных межрегиональных маршрутов регулярных перевозок между субъектами Российской Федерации Краснодарским краем и Республикой Адыгея, начальные остановочные пункты по которым расположены в границах Краснодарского края';
            sheet.cell(1, 1, 2, cols, true)
                .string(name)
                .style(captionMR);
                titlesMR.map( (title) => {
                sheet.cell(title.r, title.c, title.r + title.rows, title.c + title.cols, true)
                    .string(title.name)
                    .style(titleMR);
                if ( !!title.w ) {
                    sheet.column(title.c).setWidth(title.w);
                }
            });
        },
        generateReestr(target, data) {
            if ( target == 'MM' ) {
                this.generateReestrMM(data);
            } else {
                this.generateReestrMR(data);
            }
        },
        generateReestrMM(data) {
            const cols = 17;
            var start  = 5;
            var rtType = '!!';
            data.map( (item) => {
                if ( item[17] !== rtType ) {
                    sheet.cell(start, 1, start, cols, true)
                        .string(item[17])
                        .style(subTitleMM);
                    rtType = item[17];
                    start++;
                }
                for ( var i = 0; i<17; i++ ) {
                    if ( !!item[i] ) {
                        if ( typeof item[i] === "number") {
                            sheet.cell(start, i+1).number(item[i]).style(titleMM);
                        } else {
                            sheet.cell(start, i+1).string(item[i]).style(titleMM);
                        }                        
                    } else {
                        sheet.cell(start, i+1).string(null).style(titleMM);
                    }
                    
                }
                start++;
            });
        },
        generateReestrMR(data) {
            const cols = 12;
            var start  = 6;
            var rtType = '!!';
            data.map( (item) => {
                if ( item[22] !== rtType ) {
                    sheet.cell(start, 1, start, cols, true)
                        .string(item[22])
                        .style(subTitleMM);
                    rtType = item[22];
                    start++;
                }
                for ( var i = 0; i<22; i++ ) {
                    if ( !!item[i] ) {
                        if ( typeof item[i] === "number") {
                            sheet.cell(start, i+1).number(item[i]).style(dataMR);
                        } else {
                            sheet.cell(start, i+1).string(item[i]).style(dataMR);
                        }                        
                    } else {
                        sheet.cell(start, i+1).string(null).style(titleMM);
                    }
                    
                }
                start++;
            });
        }
    }
}
</script>